.rule-item-container {
    border: var(--ul-border-width) solid #808080;
    border-radius: 0.25rem;
    padding: 1rem;
}

.rule-item-view {
    border: none;
    appearance: none;
    padding: 0;
    margin: 0;
}

.rule-item-view+label {
    display: none!important;
}
.rule-item-view:disabled {
    color: inherit;
}
.profile-picture-circle {
    border-radius: 50%;
}

.loader {
    border: 6px solid var(--ul-primary);
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    width: 228px;
    height: 228px;
    animation-name: loading;
    animation-duration: 600ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes loading {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes fading {

    0%,
    100% {
        opacity: 0.05;
    }

    50% {
        opacity: 0.95;
    }
}

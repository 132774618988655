.verification-rules .parameter-container {
    border: var(--ul-border-width) solid #808080;
    border-radius: 0.25rem;
    padding: 1rem;
}

.form-floating .dropdown-container {
    padding: 0!important;
    border: 0!important;
}

.form-floating .rmsc .dropdown-container:focus-within {
    border: 0!important;
}
:root {
    --ul-sidebar-collapse-width: 7.5rem;
    --ul-sidebar-bg: var(--ul-white);
    --ul-sidebar-menu-color: var(--ul-black);
    --ul-sidebar-item-color: var(--ul-neutral-300);
    --ul-sidebar-item-bg-hover: var(--ul-primary-50);
    --ul-sidebar-item-bg-active: var(--ul-primary-50);
    --ul-sidebar-item-color-active: var(--ul-primary);
    --ul-navbar-height: 5.8rem;
}

.app-sidebar {
    background: var(--ul-sidebar-bg);
    flex-direction: column;
    height: 100vh;
    padding-top: var(--ul-navbar-height);
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
}

.app-sidebar .nav-item {
    margin: 0 .75rem .5rem .75rem;
    border-left: solid .25rem var(--ul-sidebar-bg);
}

.app-sidebar .nav-item .nav-link {
    color: var(--ul-sidebar-item-color);
}

.app-sidebar .nav-item:hover {
    background: var(--ul-sidebar-item-bg-hover);
}

.app-sidebar .nav-item.active {
    background: var(--ul-sidebar-item-bg-active);
    border-left: solid .25rem var(--ul-sidebar-item-color-active);
}

.app-sidebar .nav-item.active .nav-link {
    color: var(--ul-sidebar-item-color-active)!important;
}

.app-sidebar .nav-item .nav-item-label {
    line-height: var(--bs-body-font-size);
    font-size: 0.875rem;
    font-weight: 500;
}

.sidebar-container {
    position: fixed;
    width: var(--ul-sidebar-collapse-width);
    z-index: 100;
}

.main-container {
    padding-top: var(--ul-navbar-height);
    transition: margin-left .5s;
    margin-left: var(--ul-sidebar-collapse-width);
}

.app-sidebar .nav-item .nav-link i {
    padding-left: .25rem;
    padding-right: .25rem;
    font-size: 2.25rem;
}

.app-navbar {
    background: var(--ul-sidebar-bg);
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
    z-index: 10000;
}

.app-navbar .app-logo {
    height: 2.758rem;
}

.app-navbar .menu-btn {
    background: transparent;
    border: none;
    border-radius: var(--ul-border-radius);
}
.app-navbar .menu-btn:hover {
    background: var(--ul-primary-50);
}


@media (max-width: 767.98px) {
    .app-navbar .app-logo {
        width: 2.758rem;
        object-fit: cover;
        object-position: left;
    }
}

.app-navbar .user-profile-pic {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
}


.no-sidebar .sidebar-container {
    display: none;
}

.no-sidebar .main-container {
    margin-left: 0;
}
@keyframes ul-loading-animation {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.ul-loading-animation div {
    left: 48.5px;
    top: 20px;
    position: absolute;
    animation: ul-loading-animation linear 1s infinite;
    background: #ec6031;
    width: 3px;
    height: 16px;
    border-radius: 0.8px / 0.8px;
    transform-origin: 1.5px 30px;
}

.ul-loading-animation div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9375s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(2) {
    transform: rotate(22.5deg);
    animation-delay: -0.875s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(3) {
    transform: rotate(45deg);
    animation-delay: -0.8125s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(4) {
    transform: rotate(67.5deg);
    animation-delay: -0.75s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(5) {
    transform: rotate(90deg);
    animation-delay: -0.6875s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(6) {
    transform: rotate(112.5deg);
    animation-delay: -0.625s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(7) {
    transform: rotate(135deg);
    animation-delay: -0.5625s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(8) {
    transform: rotate(157.5deg);
    animation-delay: -0.5s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(9) {
    transform: rotate(180deg);
    animation-delay: -0.4375s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(10) {
    transform: rotate(202.5deg);
    animation-delay: -0.375s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(11) {
    transform: rotate(225deg);
    animation-delay: -0.3125s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(12) {
    transform: rotate(247.5deg);
    animation-delay: -0.25s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(13) {
    transform: rotate(270deg);
    animation-delay: -0.1875s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(14) {
    transform: rotate(292.5deg);
    animation-delay: -0.125s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(15) {
    transform: rotate(315deg);
    animation-delay: -0.0625s;
    background: #ec6031;
}

.ul-loading-animation div:nth-child(16) {
    transform: rotate(337.5deg);
    animation-delay: 0s;
    background: #ec6031;
}

.ul-loading-spinner {
    width: 100px;
    height: 100px;
    display: inline-block;
    overflow: hidden;
    background: none;
}

.ul-loading-animation {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ul-loading-animation div {
    box-sizing: content-box;
}
.verification-success-pill {
    padding: 0.5rem 2rem !important;
    font-size: 0.875rem !important;
    border: 0;
    border-radius: 2rem;
    background-color: var(--ul-success-50);
}

.verification-error-pill {
    padding: 0.5rem 2rem !important;
    font-size: 0.875rem !important;
    border: 0;
    border-radius: 2rem;
    background-color: var(--ul-error-50);
}
.ul-custom-colorpicker .form-control:focus {
    box-shadow: none!important;
}
.ul-custom-colorpicker input:nth-child(1) {
    width: calc(100% - 3rem);
    display: inline-flex;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

.ul-custom-colorpicker input:nth-child(2) {
    display: inline-flex;
    vertical-align: bottom;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
}
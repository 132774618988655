@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
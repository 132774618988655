.app-pagination .page-item {
    margin-right: 1rem;
}

.app-pagination .page-item:last-child {
    margin-right: 0;
}

.app-pagination .page-item .page-link:not(:active) {
    background-color: var(--ul-white);
}

.page-link.active, .active > .page-link {
    background-color: var(--ul-pagination-active-bg)!important;
}

.search-filters-container {
    background-color: #ffffff;
    border: none;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
}

.rule-container {
    background-color: #ffffff;
    border: none;
}

.select-placeholder {
    color: rgb(90, 92, 94);
}
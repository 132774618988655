// Color mode Mixin
$color-mode-type: data !default;

@mixin color-mode($mode: light, $root: false) {
    @if $color-mode-type =="media-query" {
        @if $root ==true {
            @media (prefers-color-scheme: $mode) {
                :root {
                    @content;
                }
            }
        }

        @else {
            @media (prefers-color-scheme: $mode) {
                @content;
            }
        }
    }

    @else {
        [data-bs-theme="#{$mode}"] {
            @content;
        }
    }
}

// Prefix
$prefix: ul-;

//Variables
$font-family-sans-serif: Roboto;
$enable-dark-mode: true;
$body-bg: #F6F6F6;
$input-border-color: #808080;
$input-border-radius: .25rem;
$dropdown-link-color: #808080;

// Theme color variables
$primary: #ec6031;
$secondary: #424242;
$success: #2e7d32;
$info: #0dcaf0;
$warning: #edc647;
$danger: #d32f2f;
$light: #ffffff;
$dark: #000000;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark
);

$primary-50: #FFEEE8;
$primary-100: #FFC9B7;
$primary-700: #B53A10;
$neutral-50: #F6F6F6;
$neutral-100: #C4C4C4;
$neutral-300: #808080;
$neutral-400: #686868;
$neutral-700: #2F2F2F;

$success-50: #eaf2eb;
$success-100: #bed7bf;
$success-300: #73a876;
$success-500: #2e7d32;
$success-700: #215924;
$success-900: #133515;


$error-50: #fbeaea;
$error-100: #f1bfbf;
$error-300: #e27474;
$error-500: #d32f2f;
$error-700: #962121;
$error-900: #591414;


:root {
    --#{$prefix}primary-50: #{$primary-50};
    --#{$prefix}primary-100: #{$primary-100};
    --#{$prefix}primary-700: #{$primary-700};

    --#{$prefix}neutral-50: #{$neutral-50};
    --#{$prefix}neutral-100: #{$neutral-100};
    --#{$prefix}neutral-300: #{$neutral-300};
    --#{$prefix}neutral-400: #{$neutral-400};
    --#{$prefix}neutral-700: #{$neutral-700};

    --#{$prefix}success-50: #{$success-50};
    --#{$prefix}success-100: #{$success-100};
    --#{$prefix}success-300: #{$success-300};
    --#{$prefix}success-500: #{$success-500};
    --#{$prefix}success-700: #{$success-700};
    --#{$prefix}success-900: #{$success-900};

    --#{$prefix}error-50: #{$error-50};
    --#{$prefix}error-100: #{$error-100};
    --#{$prefix}error-300: #{$error-300};
    --#{$prefix}error-500: #{$error-500};
    --#{$prefix}error-700: #{$error-700};
    --#{$prefix}error-900: #{$error-900};

    --input-text: #808080;
}

.btn {
    padding: .5rem 2rem !important;
    font-size: 0.875rem !important;
}

.btn-primary {
    --#{$prefix}btn-color: #{$light} !important;
    --#{$prefix}btn-hover-color: #{$light} !important;
    --#{$prefix}btn-hover-bg: #{$primary-700} !important;
    --#{$prefix}btn-hover-border-color: #{$primary-700} !important;
    --#{$prefix}btn-active-color: #{$light} !important;
    --#{$prefix}btn-active-bg: #{$primary-700} !important;
    --#{$prefix}btn-active-border-color: #{$primary-700} !important;
    --#{$prefix}btn-disabled-color: #{$neutral-400} !important;
    --#{$prefix}btn-disabled-bg: #{$neutral-100} !important;
    --#{$prefix}btn-disabled-border-color: #{$neutral-100} !important;
    font-weight: 500 !important;
}

.btn-outline-primary {
    --#{$prefix}btn-hover-color: #{$primary} !important;
    --#{$prefix}btn-hover-bg: #{$primary-50} !important;
    --#{$prefix}btn-hover-border-color: #{$primary} !important;
    --#{$prefix}btn-active-color: #{$primary} !important;
    --#{$prefix}btn-active-bg: #{$primary-50} !important;
    --#{$prefix}btn-active-border-color: #{$primary} !important;
    --#{$prefix}btn-disabled-color: #{$neutral-400} !important;
    --#{$prefix}btn-disabled-border-color: #{$neutral-100} !important;
}

.btn-link {
    --#{$prefix}btn-color: #{$primary} !important;
    --#{$prefix}btn-hover-color: #{$primary} !important;
    --#{$prefix}btn-hover-bg: #{$primary-50} !important;
    --#{$prefix}btn-active-color: #{$primary} !important;
    --#{$prefix}btn-disabled-color: #{$neutral-400} !important;
    text-decoration: none !important;
}

h1 {
    font-size: 6rem !important;
    font-weight: 300 !important;
    // letter-spacing: -0.938rem!important;
}

h2 {
    font-size: 3.75rem !important;
    font-weight: 300 !important;
    // letter-spacing: -0.031rem!important;
}

h3 {
    font-size: 3rem !important;
    font-weight: 400 !important;
    // letter-spacing: 0 !important;
}

h4 {
    font-size: 2.125rem !important;
    font-weight: 400 !important;
    // letter-spacing: 0.016 !important;
}

h5 {
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    // letter-spacing: 0 !important;
}

h6 {
    font-size: 1.25rem !important;
    font-weight: 400 !important;
    // letter-spacing: 0.009 !important;
}

.subtitle-1 {
    font-size: 1rem !important;
    font-weight: 400 !important;
    // letter-spacing: 0.009 !important;
}

.subtitle-2 {
    font-size: .875rem !important;
    font-weight: 500 !important;
    // letter-spacing: 0.094 !important;
}

.body-1 {
    font-size: 1rem !important;
    font-weight: 400 !important;
    // letter-spacing: 0.009 !important;
}

.body-2 {
    font-size: .875rem !important;
    font-weight: 400 !important;
    // letter-spacing: 0.094 !important;
}

.caption {
    font-size: .75rem !important;
    font-weight: 400 !important;
    // letter-spacing: 0.025 !important;
}

a {
    text-decoration: none !important;
}

.form-control,
.form-select {
    background-color: #{$light} !important;
    border-color: #{$input-border-color};
}

.form-floating>label::after {
    background-color: #{$light} !important;
}

.input-group-with-icon .form-control {
    border-top-right-radius: var(--#{$prefix}border-radius) !important;
    border-bottom-right-radius: var(--#{$prefix}border-radius) !important;
    padding-right: 1.5rem;
}

.input-group-with-icon i {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    margin-right: .75rem;
    vertical-align: middle;
    display: flex;
    align-items: center;
    color: #{$input-border-color};
    z-index: 1;
}

.alert-success {
    background-color: var(--ul-success-50)!important;
}

.alert-danger {
    background-color: var(--ul-error-50)!important;
}

.hidden {
    display: none !important;
}

.dropdown-menu {
    background-color: #{$light} !important;
}

input[type=checkbox] {
    accent-color: #{$primary} !important;
}

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";